import React, {useEffect, useMemo} from 'react'
import MapStage from '@/components/shared/map/stage/MapStage'
import MapCoverLayer from './MapCoverLayer'
import PolygonsLayer from '../polygon/PolygonsLayer'
import PointsLayer from '../point/PointsLayer'
import {useLayerView} from '@/api/hooks/useLayerView'
import {useMetadata} from '@/api/hooks/useMetadata'
import {useBookingsForLayer} from '@/api/hooks/useBookingsForLayer'
import {useGlobalStore} from '@/stores/globalStore'
import {useProjectStore} from '@/stores/projectStore'
import {useMapStore} from '@/stores/mapStore'
import {formatLocalDateToAPI} from '@/utils/helpers/dates.helpers'
import {addMinutes} from 'date-fns'
import Tooltip from '../tooltip/Tooltip'
import {useQuery} from "react-query";
import axios from "axios";
import {useUserCardFields} from "@/api/hooks/useUserCardFields";
import {useTree} from "@/api/hooks/useTree";
import {checkHidden} from "@/components/layout/Sidebar/Layers/TreeItem";
import {RolesEnum, useUserStore} from "@/stores/userStore";

const dispatchLoaded = () => {
  const event = new CustomEvent('map-loaded');
  document.dispatchEvent(event);
};

const MapContainer = () => {
  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const selection = useGlobalStore((state) => state.selection);
  const nodes = useProjectStore((state) => state.nodes);
  const setMapLayer = useMapStore((state) => state.setMapLayer);
  const role = useUserStore((state) => state.role)

  const node = nodes.find((n) => n.id == activeLayer);
  const hasOwnView = node?.ownView;

  const layer = hasOwnView ? node.id : node?.parent || activeLayer;

  const { layerView, isSuccess: layerViewLoading } = useLayerView(
    Number(layer)
  )
  const { layers } = useTree()
  const { metadata } = useMetadata();

  const { data: settings } = useQuery(
      ['settings'],
      () => axios.get('/settings.json'),
  )

  const { data: userFields } = useUserCardFields()

  const { data, isSuccess } = useBookingsForLayer(
    Number(layer),
    formatLocalDateToAPI(selection.startDate),
    formatLocalDateToAPI(addMinutes(selection.endDate, -30))
  );

  const userDepartmentFieldId = useMemo(() => userFields?.fields.find(field => field.label === '#department')?.uid || null, [userFields])

  useEffect(() => {
    setMapLayer(Number(layer));
  }, [layer, activeLayer]);

  useEffect(() => {
    if (isSuccess && layerViewLoading) {
      dispatchLoaded();
    }
  }, [isSuccess, layerViewLoading, activeLayer]);

  const accessablePolygons = useMemo(() => layerView?.polygons.filter(polygon => {
    const curr = layers?.nodes.find(n => n.id == polygon.id)
    if (!curr) return true

    const isHidden = checkHidden(curr, metadata)

    return role === RolesEnum.Admin ? true : isHidden
  }), [layers?.nodes, layerView?.polygons])

  return (
    <MapStage>
      <MapCoverLayer view={layerView?.view} />
      <PolygonsLayer polygons={accessablePolygons} />
      <PointsLayer
          userDepartmentFieldId={userDepartmentFieldId}
          colors={settings?.data?.colors || {}}
          nodes={metadata?.rawNodes}
          points={layerView?.points}
          options={layerView?.options}
          bookings={data?.bookings}
      />
      <Tooltip />
    </MapStage>
  );
};

MapContainer.whyDidYouRender = true;

export default MapContainer;
