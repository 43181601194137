import {useQuery} from "react-query";
import axios from "axios";

const useSettings = () => {
    return useQuery(
        ['settings'],
        () => axios.get('/settings.json'),
        {
            select: ({data}) => ({
                ...data,
                departments: Object.keys(data['colors']) || [],
            })
        }
    )
}

export default useSettings