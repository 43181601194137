import { useToast } from '@/components/shared/toast/useToast';
import useAuthedQuery from '@/hooks/useAuthedQuery';
import { useProject } from '@/hooks/useProject';
import { LayerService } from '../services/layer.service';

export const useLayerInfo = (layer?: number | null) => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const { data, isLoading, refetch } = useAuthedQuery(
        ['layer_info', layer],
        () => LayerService.getLayerInfo({
            workspaceId,
            projectId,
            layerId: Number(layer)
        }),
        {
            enabled: !!workspaceId && !!projectId && !!layer,
            select: ({ data }) => ({
                ...data,
                info: data.layer_info,
            }),
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить данные о слое' }, { variant: 'error' })
            }
        }
    )

    return { data, isLoading, refetch }
}