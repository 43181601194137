import { useToast } from '@/components/shared/toast/useToast';
import useAuthedQuery from '@/hooks/useAuthedQuery';
import { useProject } from '@/hooks/useProject';
import { UserService } from '../services/user.service';


export const useUsersListerFields = () => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const { data,  isLoading, refetch } = useAuthedQuery(
        ['extend_runtime_lister', workspaceId, projectId],
        () => UserService.getExtraFields({ workspaceId: Number(workspaceId), projectId: Number(projectId) }),
        {
            enabled: !!(workspaceId && projectId), refetchOnMount: false,
            select: ({ data }) => ({ ...data, fields: data?.runtime_fields || [] }),
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить список дополнительных полей' }, { variant: 'error' })
            }
        }
    )

    return { data, isLoading, refetch }
}