import { sizes } from '@/ui/media'
import { DefaultTheme } from 'styled-components'

export const blueTheme: DefaultTheme = {
    grid: {
        gutter: 8,
        breakpoints: sizes
    },
    palette: {
        default: {
            main: '#fff',
            text: 'rgb(124,124,124)'
        },
        accent: {
            main: '#FFF',
        },
        primary: {
            main: '#7c002f',
        },
        background: {
            primary: '#7c002f',
            default: '#fff',
        },
        text: {
            primary: "#fff",
            secondary: "#000",
            label: '#fff',
            input: 'rgba(0, 0, 0, 0.25)',
        }
    },
}

export default blueTheme