import axios from "axios";
import qs from 'query-string';
import { bookingsAPI } from './bookings';
import { layerAPI } from './layer';
import { projectAPI } from "./project";
import { userAPI } from './user';

// export const BASE_DOMAIN = 'https://leader.ironstar.pw/'
// export const BASE_DOMAIN = 'https://prodoadmin.officescheme.ru/'
export const BASE_DOMAIN = 'https://demoadmin.officescheme.ru/'
// export const BASE_DOMAIN = '/api9v4/'
// export const BASE_DOMAIN = 'https://windemoadmin.officescheme.ru/'
// export const BASE_DOMAIN = 'https://mapoffice-api.obit.ru/'
// export const BASE_DOMAIN = 'https://som1.sminex.com/'
// export const BASE_DOMAIN = 'https://placeadmin.tmk-group.com/'

export const redirectSAML = (authRef: string, wId?: number, pId?: number) => {
  const queryParams = qs.parse(location.search)

  const workspaceId = wId || queryParams.workspace_id
  const projectId = pId || queryParams.project_id
  
  let to = `${location.origin}/project/${Number(workspaceId)}/${Number(projectId)}`

  if (location.href.includes('/project')) {
    to = location.href
  }

  location.href = authRef + `&lmreturnto=${to}`
}
export const api = axios.create({
  withCredentials: false,
  // withCredentials: true,
  baseURL: BASE_DOMAIN,
  headers: {
    'x-ws-common-auth': localStorage.getItem('token') || '',
  },
})

api.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token') || ''
    config.headers = {
      'x-ws-common-auth': token
    }
    return config
  }
)

api.interceptors.response.use(
  async response => {
    const token = response.data?.ws_auth?.wst || '-invalid-'

    // const status = response.data?.status
    // const authRef = response.data?.authref

    // Redirect to SAML auth
    // if (status == StatusResponseEnum.NotAuthorized && authRef) {
    //   const queryParams = qs.parse(location.search)

    //   const workspaceId = queryParams.workspace_id
    //   const projectId = queryParams.project_id
      
    //   let to = `${location.origin}/project/${Number(workspaceId)}/${Number(projectId)}`

    //   if (location.href.includes('/project')) {
    //     to = location.href
    //   }

    //   location.href = authRef + `&lmreturnto=${to}`

    //   return 
    // }

    if (token != '-invalid-') {
      localStorage.setItem('token', token)
    }
 
    return response
  }
)

export const API = {
  user: userAPI,
  projects: projectAPI,
  bookings: bookingsAPI,
  layer: layerAPI,
}


export enum StatusResponseEnum {
  Success = 'ok',
  Error = 'error',
  NotAuthorized = 'auth',
}

export type ApiResponseType = {
  status: StatusResponseEnum
  controller: string
  authref?: string
  authorization: any,
  error_info: {
    message: string
    place: string
    trace: string
  }
  ws_auth: {
    wst: string
  }
  // project_access: string
  // project_user_data: {
  //     role: string
  //     plugin_data: {
  //         [key: string]: any
  //     }
  // },
}