import { BASE_DOMAIN } from '@/api';
import { useProject } from '@/hooks/useProject';
import { useUserStore } from '@/stores/userStore';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

const LogoutPage = () => {
    const setUser = useUserStore(state => state.setUser)
    const setAuthenticated = useUserStore(state => state.setAuthenticated)
    const {workspaceId, projectId} = useProject()

    const queryClient = useQueryClient()

    useEffect(() => {
        localStorage.removeItem('token')
        setUser(null)
        setAuthenticated(false)
        queryClient.clear()


        localStorage.removeItem('saml')
        const redirectTo = BASE_DOMAIN + `App/Auth/Logout?workspace_id=${workspaceId}&return_uri=${location.origin}/`
            
        location.href = redirectTo
       
    }, [])

    return null
}

export default LogoutPage