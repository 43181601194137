import { Place } from '@/components/Elements/ElementsListItem';
import { useProjectStore } from '@/stores/projectStore';
import styled, { css } from 'styled-components';

const MobilePlaceItem = ({ place, selected, handleSelection }) => {
    const placeSelected = selected?.id || null

    const { nodes } = useProjectStore()
    const placename = nodes.find(tree => tree.id == Number(place.parent))

    const handleOnClick = () => {
        handleSelection({
            place: placename?.name || 'Не указано',
            spot: place.name,
            id: place.id
        })
    }


    return (
        <ItemWrapper
            $selected={placeSelected == place.id}
            onClick={handleOnClick}
        >
            <Name>{place.name}</Name>
            <PropsWrapper>
                <Property>
                    <PropName>Тип</PropName>
                    <PropValue>{place.type_name}</PropValue>
                </Property>
                <Property>
                    <PropName>Расположение</PropName>
                    <PropValue>
                        <Place item={place} />
                    </PropValue>
                </Property>
            </PropsWrapper>
        </ItemWrapper>
    )
}

export default MobilePlaceItem

const ItemWrapper = styled.div<{ $selected?: boolean }>`
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    color: #000;

    ${({ $selected }) => $selected && css`
        background: #7c002f;
        border: 1px solid #7c002f;
        border-radius: 3px;
        color: #fff;
    `}

    &:not(:last-child) {
        margin-bottom: 10px;
    }
`

const PropsWrapper = styled.div`
    margin: 6px 0 12px 0;
`

const PropName = styled.div`
    max-width: 120px;
    width: 100%;
`
const PropValue = styled.div`
    width: 100%;
    font-weight: 700;
`

const Property = styled.div`
    display: flex;
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const Name = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: inherit;
`