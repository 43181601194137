import { StatusResponseEnum } from '@/api'
import {
	QueryFunction,
	QueryKey,
	useQuery,
	UseQueryOptions,
	UseQueryResult
} from 'react-query'
import { useNavigate } from 'react-router'
import { useProject } from './useProject'

// TODO: implement typescript variant for 1 & 2 params
export function useAuthedQuery<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<
		UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
		'queryKey' | 'queryFn'
	>
): UseQueryResult<TData, TError> {
	const navigate = useNavigate()
	const project = useProject()
	const props = { queryKey, queryFn, ...options }
	const query: any = useQuery(props)

	if (query?.data?.['status'] === StatusResponseEnum.NotAuthorized) {
		navigate('/logout', { state: project })
	}

	return query
}

export default useAuthedQuery
